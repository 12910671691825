import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Automate your DeFi portfolio`}</h2>
    <p>{`Quit protocol hopping and start earning. Create and easily invest in trusted portfolios, earning real returns.`}</p>
    <div>
      <h3>{`Something for everyone`}</h3>
      <p>{`Provide liquidity and farm competitive returns without speculation. Swap assets with low slippage and earn rewards. Join an Array and manage your crypto with no hassle.`}</p>
      <h3>{`Let’s make DeFi easy`}</h3>
      <p>{`By aligning incentives between users, traders and liquidity providers, the ArrayDAO keeps DeFi collaborative by distributing rewards where they matter most. With Array, passive DeFi is possible. No longer will you need to chase APY and wipe out your profits with transaction fees.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      