import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Backed by the best`}</h2>
    <p>{`The Array protocol has been crafted to solve the fundamental issues within the DeFi space. Developed and tested by industry veterans, the ArrayDAO bonds and backs tokens, locking real value as collateral behind each Array token, minimizing volatility. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      