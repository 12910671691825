import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`The future of asset management is here.`}</h2>
    <p>{`Algorithmically diversify based on personal risk tolerance. Quit being limited by a handful of strategies- our tools allow you to build and deploy yourself, earning performance fees in the process.`}</p>
    <ul>
      <li parentName="ul">{`Build your own Arrays and share with the community`}</li>
      <li parentName="ul">{`Save gas by having Array handle portfolio rebalancing and farming`}</li>
      <li parentName="ul">{`Let others join your Arrays and set custom performance fees.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      